document.addEventListener('alpine:init', () => {
  Alpine.data('budgetReport', (idBudget, locked, json) => ({
    idBudget: idBudget,
    locked: locked,
    data: JSON.parse(json),
    months: [],
    loading: true,

    formatValue: function (value) {
      const formattedValue = Math.abs(value).toFixed(2);
      const color = value < 0 ? 'red' : 'green';
      const sign = value < 0 ? '-' : '';
      return `<span style="color:${color};">${sign}$${formattedValue}</span>`;
    },
    calculateValue: function (value) {
      return isNaN(value) ? 0 : parseFloat(value);
    },
    calculateUserTotal: function (idUser) {
      let userData = this.data[idUser]?.data || {};
      return Object.values(userData).reduce((partialSum, a) => {
        return partialSum + this.calculateValue(a[0]);
      }, 0);
    },
    calculateUserActuals: function (idUser) {
      let userData = this.data[idUser]?.data || {};

      return Object.values(userData).reduce((actual, a) => {
        return actual + this.calculateValue(a[1]);
      }, 0);
    },
    calculateRowTotal: function (idUser) {
      return this.calculateUserActuals(idUser) - this.calculateUserTotal(idUser);
    },
    calculateMonthTotals: function (month) {
      return this.calculateMonthValues(month, 0);
    },
    calculateMonthActuals: function (month) {
      return this.calculateMonthValues(month, 1);
    },
    calculateMonthValues: function (month, index) {
      return Object.values(this.data).reduce((sum, obj) => {
        return sum + this.calculateValue(obj.data?.[month]?.[index] ?? 0);
      }, 0);
    },

    calculateFullTotals: function (index) {
      return Object.values(this.data).reduce((sum, obj) => {
        let value = Object.values(obj.data).reduce((innerSum, arr) => innerSum + this.calculateValue(arr[index]), 0);
        return sum + value;
      }, 0);
    },

    calculateFullBudgetTotals: function () {
      return this.calculateFullTotals(0);
    },

    calculateFullActualsTotals: function () {
      return this.calculateFullTotals(1);
    },

    calculateYTD: function (index) {
      let sum = 0;
      while (index >= 0) {
        sum += parseFloat(this.calculateMonthActuals(this.months[index])) - parseFloat(this.calculateMonthTotals(this.months[index]));

        index--;
      }

      return sum;
    },

    updateBudget: function (event) {
      let input = event.target;

      if (!input) {
        return;
      }

      if (isNaN(input.value)) {
        input.value = 0;
      }

      const postData = {
        value: input.value,
        id_budget: this.idBudget,
        id_entity: input.dataset.idEntity,
        month: input.dataset.month
      };

      this.postRequest('/freedom3/Budget/updateBudget', postData);
    },

    toggleLock: function () {
      const message = 'Do you want to ' + (this.locked ? 'unlock' : 'lock') + ' this budget?';
      SIT._confirmMessage(
        'LockBudget',
        message,
        function () {
          const postData = {
            id_budget: this.idBudget,
            locked: !this.locked
          };

          this.postRequest('/freedom3/Budget/lockBudget', postData, () => {
            this.locked = !this.locked;
            Notifications.showSuccessMessage('This budget is now ' + (this.locked ? 'locked' : 'unlocked') + '.');
          }, 'LockBudget');
        }.bind(this), this.locked ? 'Unlock' : 'Lock', 'delete_btn');
    },

    clearBudget: function () {
      SIT._confirmMessage(
        'ClearBudget',
        'Do you want to clear this budget?',
        function () {
          const postData = {
            id_budget: this.idBudget,
          };

          this.postRequest('/freedom3/Budget/clearBudget', postData, (data) => {
            Notifications.showSuccessMessage('The budget has been cleared.');
            this.data = data.data;
            this.months = data.months;
          }, 'ClearBudget');
        }.bind(this), 'Clear', 'delete_btn');
    },

    getData: function () {
      const url = '/freedom3/Budget/getData?id_budget=' + this.idBudget;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      };

      fetch(url, options)
        .then(this.handleFetchResponse)
        .then(data => {
          this.data = data.data;
          this.months = data.months;

          this.loading = false;
        })
        .catch(error => {
          Notifications.showErrorMessage('Something went wrong, please try again.');
        });
    },

    postRequest: function (url, postData, callback, confirmId) {
      const formData = new URLSearchParams();
      for (const key in postData) {
        formData.append(key, postData[key]);
      }

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formData
      };

      fetch(url, options)
        .then(this.handleFetchResponse)
        .then(data => {
          if (callback) callback(data);
        })
        .catch(error => {
          Notifications.showErrorMessage('Something went wrong, please try again.');
        })
        .finally(() => {
          if (ColorboxManager.get('sitConfirmMessage-' + confirmId)) {
            ColorboxManager.get('sitConfirmMessage-' + confirmId).close();
          }
        });
    },

    handleFetchResponse: function (response) {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },

    init: function () {
      this.getData();
    }
  }));
});